<script setup lang="ts">
const props = defineProps<{
  title?: string
  overflowY?: boolean
  noContentPadding?: boolean
}>()

const overflowClass = computed(() =>
  props.overflowY ? 'grow overflow-y-auto' : '',
)

const slots = useSlots()
</script>

<template>
  <UCard
    :ui="{
      header: {
        base: 'header flex items-center justify-between',
      },
      body: {
        base: overflowClass,
        padding: props.noContentPadding ? 'p-0 sm:p-0' : 'px-4 py-5 sm:p-6',
      },
    }"
  >
    <template v-if="props.title || slots.header || slots.headerIcon" #header>
      <div class="mr-4 flex items-center overflow-hidden">
        <p v-if="props.title" class="truncate font-bold text-gray-700">
          {{ props.title }}
        </p>
        <slot name="header" />
      </div>
      <div v-if="slots.headerIcon" class="flex">
        <slot name="headerIcon" />
      </div>
    </template>

    <slot name="content" />

    <template v-if="slots.footer" #footer>
      <div class="flex">
        <slot name="footer" />
      </div>
    </template>
  </UCard>
</template>
